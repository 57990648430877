import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {CardActions, Collapse, IconButton, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

type Item = {
    timestamp: number,
    temperature: number,
    pressure: number,
    humidity: number,
    voltage: number,
    bootCount: number
}

const useStyles = makeStyles((theme: Theme) => ({
    chart: {
        marginTop: theme.spacing(2)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
}));

function DailyChart(): JSX.Element | null {
    const classes = useStyles();
    const [error, setError] = useState<Record<string, unknown> | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState<Item[] | null>(null);
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // User has switched back to the tab
    const onFocus = () => {
        console.log('Tab is in focus');
        fetch('https://api.home.marosseleng.com/weather/out/today')
            .then(res => res.json())
            .then(
                (result: Item[]) => {
                    setItem(result);
                    setIsLoaded(true);
                    setError(null);
                },
                (error) => {
                    setItem(null);
                    setIsLoaded(true);
                    setError(error);
                }
            );
    };

    useEffect(() => {
        onFocus();
        window.addEventListener('focus', onFocus);

        return () => {
            window.removeEventListener('focus', onFocus);
        };
    }, []);

    let xAxisEnd = 0;
    if (item && item[0]) {
        xAxisEnd = item[0]['timestamp'] + 86400000;
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h5">
                    Teplota, Vlhkosť, Tlak (dnes)
                </Typography>
                <ResponsiveContainer height={300} className={classes.chart}>
                    <ComposedChart data={item ?? []}>
                        <YAxis yAxisId={'temperature'} dataKey="temperature" unit={'°C'} mirror={true}/>
                        <YAxis orientation={'right'} yAxisId={'humidity'} dataKey="humidity" unit={'%'} mirror={true}
                            domain={[0, 100]}/>
                        <YAxis hide={true} yAxisId={'pressure'} dataKey="pressure"
                            domain={['dataMin - 5', 'dataMax + 5']} unit={'hPa'}/>
                        <XAxis type={'number'} dataKey={'timestamp'} domain={['dataMin', xAxisEnd]} scale={'time'}
                            tickFormatter={(tick) => new Date(tick).toLocaleTimeString()} minTickGap={15}/>
                        <Tooltip labelFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}/>
                        <Legend/>
                        <Line type="monotone" yAxisId={'temperature'} dataKey="temperature" stroke="#E64A19" dot={false}
                            legendType={'plainline'} name="Teplota" unit={'°C'} strokeWidth={2}/>
                        <Line type="monotone" yAxisId={'humidity'} dataKey="humidity" stroke="#FFC107" dot={false}
                            legendType={'plainline'} name="Vlhkosť" unit={'%'} strokeWidth={2}/>
                        <Line type="monotone" yAxisId={'pressure'} dataKey="pressure" stroke="#009688" dot={false}
                            legendType={'plainline'} name="Tlak" unit={'hPa'}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreRoundedIcon/>
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <ResponsiveContainer height={300} className={classes.chart}>
                        <ComposedChart data={item ?? []}>
                            <YAxis yAxisId={'voltage'} dataKey="voltage" unit={'mV'} mirror={true}
                                domain={['dataMin - 5', 'dataMax + 5']}/>
                            <YAxis orientation={'right'} yAxisId={'bootCount'} dataKey="bootCount" mirror={true}
                                domain={['dataMin - 2', 'dataMax + 2']}/>
                            <XAxis type={'number'} dataKey={'timestamp'} domain={['dataMin', xAxisEnd]} scale={'time'}
                                tickFormatter={(tick) => new Date(tick).toLocaleTimeString()} minTickGap={15}/>
                            <Tooltip labelFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}/>
                            <Legend/>
                            <Line type="monotone" yAxisId={'voltage'} dataKey="voltage" stroke="#D32F2F" dot={false}
                                legendType={'plainline'} name="Napätie" unit={'mV'} strokeWidth={2}/>
                            <Line type="monotone" yAxisId={'bootCount'} dataKey="bootCount" stroke="#757575" dot={false}
                                legendType={'plainline'} name="Boot Count"/>
                        </ComposedChart>
                    </ResponsiveContainer>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default DailyChart;
