import React from 'react';
import './App.css';
import {Box, Grid, makeStyles, Theme} from '@material-ui/core';
import AppBar from './AppBar';
import CurrentWeather from './CurrentWeather';
import DailyChart from './DailyChart';
import LongtermChart from './LongtermChart';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(2)
    }
}));

const App: () => JSX.Element = () => {
    const classes = useStyles();
    return (
        <Grid container direction={'column'}>
            <Grid item>
                <AppBar/>
            </Grid>
            <Grid container item className={classes.root}>
                <Grid item xs={false} sm={false} md={1}/>
                <Grid container direction={'column'} spacing={2} item xs={12} md={10}>
                    <Grid item>
                        <CurrentWeather/>
                    </Grid>
                    <Grid item>
                        <DailyChart />
                    </Grid>
                    <Grid item>
                        <LongtermChart />
                    </Grid>
                </Grid>
                <Grid item xs={false} sm={false} md={1}/>
            </Grid>
        </Grid>
    );
};

export default App;
