import React, {useEffect, useState} from 'react';
import {CardActions, Collapse, Grid, IconButton, makeStyles, Theme} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import BatteryAlertRoundedIcon from '@material-ui/icons/BatteryAlertRounded'; // <2850
import Battery20RoundedIcon from '@material-ui/icons/Battery20Rounded'; // 2900+
import Battery30RoundedIcon from '@material-ui/icons/Battery30Rounded'; // 2925+
import Battery50RoundedIcon from '@material-ui/icons/Battery50Rounded'; // 2975+
import Battery60RoundedIcon from '@material-ui/icons/Battery60Rounded'; // 3000+
import Battery80RoundedIcon from '@material-ui/icons/Battery80Rounded'; // 3050+
import Battery90RoundedIcon from '@material-ui/icons/Battery90Rounded'; // 3075+
import BatteryFullRoundedIcon from '@material-ui/icons/BatteryFullRounded';
import VerticalAlignBottomRoundedIcon from '@material-ui/icons/VerticalAlignBottomRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import {setInterval} from 'timers';
import clsx from 'clsx';

type Item = {
    'temperature_out': number,
    timestamp: number,
    'min_24h': number,
    'min_24h_timestamp': number,
    'max_24h': number,
    'max_24h_timestamp': number,
    'avg_24h': number,
    pressure: number,
    humidity: number,
    batteryVoltage: number
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minWidth: 280,
    },
    pos: {
        marginBottom: theme.spacing(2),
    },
    expandedContent: {
        marginTop: theme.spacing(2),
    },
    main: {
        display: 'flex',
        'align-items': 'flex-start',
        marginBottom: theme.spacing(2)
    },
    footer: {
        display: 'flex',
        'align-items': 'flex-end'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
}));

function getBatteryIcon(voltage: number): JSX.Element {
    if (voltage < 2900) {
        return <BatteryAlertRoundedIcon color="error"/>;
    } else if (voltage < 2925) {
        return <Battery20RoundedIcon/>;
    } else if (voltage < 2975) {
        return <Battery30RoundedIcon/>;
    } else if (voltage < 3000) {
        return <Battery50RoundedIcon/>;
    } else if (voltage < 3050) {
        return <Battery60RoundedIcon/>;
    } else if (voltage < 3075) {
        return <Battery80RoundedIcon/>;
    } else if (voltage < 3100) {
        return <Battery90RoundedIcon/>;
    } else {
        return <BatteryFullRoundedIcon/>;
    }
}

function CurrentWeather(): JSX.Element | null {
    const classes = useStyles();
    const [error, setError] = useState<Record<string, unknown> | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState<Item | null>(null);
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // User has switched back to the tab
    const onFocus = () => {
        console.log('Tab is in focus');
        fetch('https://api.home.marosseleng.com/weather/out/current')
            .then(res => res.json())
            .then(
                (result: Item) => {
                    setIsLoaded(true);
                    setItem(result);
                    setError(null);
                },
                (error) => {
                    setIsLoaded(true);
                    setItem(null);
                    setError(error);
                }
            );
    };

    useEffect(() => {
        onFocus();
        window.addEventListener('focus', onFocus);
        // here put code to execute as if in componentDidMount
        return () => {
            window.removeEventListener('focus', onFocus);
        };
    }, []);
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5">
                    Brno - Žabovřesky
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {new Date(item?.timestamp ?? 0).toLocaleString()}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <div className={classes.main}>
                            <Typography variant="h2" component="span">{item?.temperature_out ?? 0}</Typography>
                            <Typography variant="h4" component="span">°C</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.main}>
                            <Typography variant="h2" component="span">{item?.humidity ?? 0}</Typography>
                            <Typography variant="h4" component="span">%</Typography>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.footer}>
                    <VerticalAlignBottomRoundedIcon/>
                    <Typography variant="body2">{item?.pressure ?? 0} hPa</Typography>
                </div>
                <div className={classes.footer}>
                    {getBatteryIcon(item?.batteryVoltage ?? 0)}
                    <Typography variant="body2">{item?.batteryVoltage ?? 0} mV</Typography>
                </div>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreRoundedIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="overline">Najnižšia teplota (24h)</Typography>
                    <Typography variant="body1">{item?.min_24h ?? -100}°C</Typography>
                    <Typography variant="caption" gutterBottom>{new Date(item?.min_24h_timestamp ?? 0).toLocaleString()}</Typography>
                    <Typography variant="overline" component="p">Priemerná teplota (24h)</Typography>
                    <Typography variant="body1" gutterBottom>{item?.avg_24h ?? -100}°C</Typography>
                    <Typography variant="overline" component="p">Najvyššia teplota (24h)</Typography>
                    <Typography variant="body1">{item?.max_24h ?? -100}°C</Typography>
                    <Typography variant="caption">{new Date(item?.max_24h_timestamp ?? 0).toLocaleString()}</Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default CurrentWeather;