import React from 'react';
import {IconButton, makeStyles, Theme, Toolbar, Typography} from '@material-ui/core';
import MaterialAppBar from '@material-ui/core/AppBar';
import {MenuRounded} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const AppBar: () => JSX.Element = () => {
    const classes = useStyles();
    return (
        <MaterialAppBar position="static">
            <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuRounded/>
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Meteostanica
                </Typography>
            </Toolbar>
        </MaterialAppBar>
    );
};

export default AppBar;