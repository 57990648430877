import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Area, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {makeStyles, Theme} from '@material-ui/core';

type Item = {
    sensorId: string,
    timestamp: number,
    temperature: number,
    temperatureMax: number,
    temperatureMin: number,
    timestampMax: number,
    timestampMin: number,
    voltage: number
}

const useStyles = makeStyles((theme: Theme) => ({
    chart: {
        marginTop: theme.spacing(2)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
}));

function LongtermChart(): JSX.Element {
    const classes = useStyles();
    const [error, setError] = useState<Record<string, unknown> | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState<Item[] | null>(null);

    // User has switched back to the tab
    const onFocus = () => {
        console.log('Tab is in focus');
        fetch('https://api.home.marosseleng.com/weather/out/longterm')
            .then(res => res.json())
            .then(
                (result: Item[]) => {
                    setItem(result);
                    setIsLoaded(true);
                    setError(null);
                },
                (error) => {
                    setItem(null);
                    setIsLoaded(true);
                    setError(error);
                }
            );
    };

    useEffect(() => {
        onFocus();
        window.addEventListener('focus', onFocus);

        return () => {
            window.removeEventListener('focus', onFocus);
        };
    }, []);

    let domainMin = null;
    let domainMax = null;

    if (item) {
        for (let i = 0; i < item.length; i++) {
            if (domainMin === null || item[i]['temperatureMin'] < domainMin) {
                domainMin = item[i]['temperatureMin'];
            }
            if (domainMax === null || item[i]['temperatureMax'] > domainMax) {
                domainMax = item[i]['temperatureMax'];
            }
        }
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h5">
                    Teplota dlhodobo
                </Typography>
                <ResponsiveContainer height={300} className={classes.chart}>
                    <ComposedChart data={item ?? []}>
                        <YAxis yAxisId={'temperature'} dataKey="temperature" unit={'°C'}
                            domain={[(domainMin ?? -100) - 5, (domainMax ?? 100) + 5]} mirror={true}/>
                        <XAxis dataKey={'day'} minTickGap={15}/>
                        <Area dataKey={'temperatureRange'} stroke="#f2a48c" fill="#fcede8" yAxisId={'temperature'}
                            name="Teplotný rozsah" unit={'°C'}/>
                        <Line yAxisId={'temperature'} dataKey="temperature" stroke="#E64A19" legendType={'plainline'}
                            name="Priemerná teplota" unit={'°C'} strokeWidth={2}/>
                        <Tooltip/>
                        <Legend/>
                    </ComposedChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
}

export default LongtermChart;